import React from "react"
import tw, { styled } from "twin.macro"
//import styled from "styled-components";
import { Container as ContainerBase } from "../misc/Layouts.js"
import logo from "../images/logo.png"
import facebookIcon from "../images/facebook-icon.svg"
import twitterIcon from "../images/twitter-icon.svg"

import defaultCardImage from "../images/shield-icon.svg"
/* import FacebookIcon from "../images/facebook-icon.svg"
import TwitterIcon from "../images/twitter-icon.svg" */
// import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
//import facebookIcon from "../images/facebook-icon.svg"
//import twitterIcon from "../images/twitter-icon.svg"

const Container = tw(ContainerBase)`bg-primary-700 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto `

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-8`
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`

const SocialLinksContainer = tw.div`mt-2`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const CopyrightText = tw.p`text-center mt-2 font-medium tracking-wide text-sm `
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>JUT</LogoText>
          </LogoContainer>
          {/* <LinksContainer>
            <Link href="#">Home</Link>
            <Link href="#">About</Link>
            <Link href="#">Contact Us</Link>
            <Link href="#">Blog</Link>
            <Link href="#">Reviews</Link>
          </LinksContainer> */}
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <img src={facebookIcon || defaultCardImage} alt="" />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <img src={twitterIcon || defaultCardImage} alt="" />
            </SocialLink>
          </SocialLinksContainer>
          <CopyrightText>
            &copy; Copyright 2020, M81 LLC. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  )
}
