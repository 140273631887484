import React from "react"
import {
  motion
} from "framer-motion"
import tw, {
  styled,
  css
} from "twin.macro"
//import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg"
//import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg"
import feather from "../util/feather"

import logo from "../images/logo.png"
import useAnimatedNavToggler from "../helpers/useAnimatedNavToggler.js"

const Header = tw.header `
  flex justify-between items-center
  max-w-screen-xl mx-auto 
`

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw `sm:hidden`,
    desktopNavLinks: tw `sm:flex`,
    mobileNavLinksContainer: tw `sm:hidden`,
  },
  md: {
    mobileNavLinks: tw `md:hidden`,
    desktopNavLinks: tw `md:flex`,
    mobileNavLinksContainer: tw `md:hidden`,
  },
  lg: {
    mobileNavLinks: tw `lg:hidden`,
    desktopNavLinks: tw `lg:flex`,
    mobileNavLinksContainer: tw `lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw `lg:hidden`,
    desktopNavLinks: tw `lg:flex`,
    mobileNavLinksContainer: tw `lg:hidden`,
  },
}

export const NavLinks = tw.div `inline-block`
export const NavLink = tw.a `
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`
export const PrimaryLink = tw(NavLink)
`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`
export const LogoLink = styled(NavLink)
`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`
export const MobileNavLinksContainer = tw.nav `flex flex-1 items-center justify-between`
export const NavToggle = tw.button `
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`
export const MobileNavLinks = motion.custom(styled.div `
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav `
  hidden lg:flex flex-1 justify-between items-center
`
export const svgStyle = css `
w-6 h-6
`
const Image = tw.img `w-16 h-16`

const NewHeader = ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  const buttonRounded = true
  const buttonRoundedCss = buttonRounded && tw `rounded-full`

  const defaultLinks = [ <
    NavLinks key = {
      1
    } >
    <
    NavLink href = "/#" > About < /NavLink> <
    NavLink href = "/features" > Features < /NavLink> <
    NavLink href = "/download" > Download < /NavLink> <
    NavLink href = "/contactus" > Contact Us < /NavLink>  <
    NavLink href = "/faq" > FAQ < /NavLink> < /
    NavLinks >
  ]
  const {
    showNavLinks,
    animation,
    toggleNavbar
  } = useAnimatedNavToggler()
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass]
  const defaultLogoLink = ( <
    LogoLink href = "/" >
    <
    img src = {
      logo
    }
    alt = "logo" / >
    JUT <
    /LogoLink>
  )
  logoLink = logoLink || defaultLogoLink
  links = links || defaultLinks
  return ( <
    Header className = {
      className || "header-light"
    } >
    <
    DesktopNavLinks css = {
      collapseBreakpointCss.desktopNavLinks
    } > {
      logoLink
    } {
      links
    } <
    /DesktopNavLinks> <
    MobileNavLinksContainer css = {
      collapseBreakpointCss.mobileNavLinksContainer
    } > {
      logoLink
    } <
    MobileNavLinks initial = {
      {
        x: "150%",
        display: "none"
      }
    }
    animate = {
      animation
    }
    css = {
      collapseBreakpointCss.mobileNavLinks
    } > {
      links
    } <
    /MobileNavLinks> <
    NavToggle onClick = {
      toggleNavbar
    }
    className = {
      showNavLinks ? "open" : "closed"
    } > {
      showNavLinks ?
      feather("x-circle", svgStyle) : feather("menu", svgStyle)
    } <
    /NavToggle> < /
    MobileNavLinksContainer > <
    /Header>
  )
}

export default NewHeader